import React, { forwardRef } from 'react'
// material-ui components
import { FormGroup, FormControl, FormLabel, FormHelperText } from '@material-ui/core'
import CustomTextButtonButton from './TextButton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  label: {
    marginBottom: theme.spacing(2),
  },
  group: {
    gap: theme.spacing(2),
    justifyContent: 'center',
  },
}))

const AvatarButtonGroup = forwardRef(
  ({ label, options, optionLabel = 'label', optionValue = 'id', onChange, value, error, ...props }, ref) => {
    const classes = useStyles()

    return (
      <FormControl ref={ref}>
        <FormLabel className={classes.label}>{label}</FormLabel>
        <FormGroup className={classes.group} row>
          {options.map(({ id, [optionLabel]: label }) => (
            <FormControl key={id}>
              <CustomTextButtonButton
                {...props}
                label={label}
                selected={id === value?.[optionValue]}
                onClick={() => onChange(options.find((o) => o[optionValue] === id))}
              />
            </FormControl>
          ))}
        </FormGroup>
        {error && <FormHelperText error>{error.message}</FormHelperText>}
      </FormControl>
    )
  }
)
export default AvatarButtonGroup
