import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  drawer: {
    padding: theme.spacing(2),
  },
  drawerPaper: {
    [theme.breakpoints.up('xs')]: {
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      width: '70vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '40vw',
    },
    [theme.breakpoints.up('xl')]: {
      width: '20vw',
    },
    padding: theme.spacing(3, 4),
  },
}))
