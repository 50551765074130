import React, { useState, forwardRef, useImperativeHandle } from 'react'
import Drawer from 'components/Drawer'
import Book from 'components/Public/Book'

export default forwardRef(({ slotTimetable, ...props }, ref) => {
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    openDrawer: () => setOpen(true),
    closeDrawer: () => setOpen(false),
  }))

  const onDiscard = () => {
    setOpen(false)
  }

  return (
    <Drawer open={open}>{slotTimetable && <Book timetable={slotTimetable} onDiscard={onDiscard} {...props} />}</Drawer>
  )
})
