import React, { useState, forwardRef, useEffect, useRef } from 'react'
import Form from './Form'
import Form2 from './Form2'
import Row from '../Row'
import Terms from './terms'
import {
  PeopleTwoTone as PeopleTwoToneIcon,
  ArrowForwardIosTwoTone as ArrowForwardIosTwoToneIcon,
} from '@material-ui/icons'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { GROUP_LABEL, SLOT_LABEL } from 'config/app'
import validationSchema from './validations'
import { yupResolver } from '@hookform/resolvers/yup'
import defaultValues from './defaults'
import { useForm } from 'react-hook-form'
import { DISPLAY_FORM } from 'config/app'
import { TNC } from 'config/app'

export default forwardRef(({ bookingData, onSave, slots, ...props }, ref) => {
  const [display, setDisplay] = useState({ title: `Choose ${GROUP_LABEL} & ${SLOT_LABEL}`, subheader: 'Choose a date' })
  const formRef = useRef()
  const tncRef = useRef()
  const hookForm = useForm({
    defaultValues: bookingData ?? defaultValues,
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (bookingData && slots) {
      setDisplay({ title: `${bookingData.group.name} / ${bookingData.slot.name}` })
      hookForm.reset(bookingData)
    }
  }, [bookingData, slots])

  const onOpenDrawer = () => {
    if (TNC) {
      tncRef.current.open()
    } else {
      formRef.current.open()
    }
  }

  const onAccept = () => {
    tncRef.current.close()
    formRef.current.open()
  }

  const onSaveForm = (data) => onSave(data).then(() => onClose())

  const onClose = () => setTimeout(() => formRef.current.close(), 100)

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Row
          avatarIcon={<PeopleTwoToneIcon />}
          title={display.title}
          actionIcon={<ArrowForwardIosTwoToneIcon />}
          onClick={onOpenDrawer}
        />
        {TNC && <Terms ref={tncRef} onAccept={onAccept} />}
        {DISPLAY_FORM === 'form1' ? (
          <Form ref={formRef} onSave={onSaveForm} onClose={onClose} hookForm={hookForm} slots={slots} {...props} />
        ) : (
          <Form2 ref={formRef} onSave={onSaveForm} onClose={onClose} hookForm={hookForm} slots={slots} {...props} />
        )}
      </GridItem>
    </GridContainer>
  )
})
