import React from 'react'
import Layout from 'components/Layout/Selection'
import SlotListing from './Listing'

export default function SlotSelection(props) {
  return (
    <Layout title="Home" {...props}>
      <SlotListing {...props} />
    </Layout>
  )
}
