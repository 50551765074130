import React, { forwardRef, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import { GridContainer, GridItem } from 'components/Grid'
import AvatarButtonGroup from 'components/CustomRadio/AvatarButtonGroup'
import TextButtonGroup from 'components/CustomRadio/TextButtonGroup'
import { Button } from 'components/CustomButtons'
import { GROUP_LABEL, SLOT_LABEL, FEE_LABEL } from 'config/app'
import Drawer from 'components/Drawer'
import { useEffect } from 'react'
import useOpenClose from 'hooks/useOpenClose'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: theme.spacing(2),
    display: 'none',
  },
  selected: {
    display: 'flex',
  },
}))

const SlotDateChooserForm = forwardRef(({ groups, slots, onSave, onClose, hookForm }, ref) => {
  const { control, handleSubmit, setValue, watch } = hookForm
  const slot = watch('slot')
  const group = watch('group')
  const fee = watch('fee')
  const [step, setStep] = useState(0)
  const { open } = useOpenClose(ref)
  const groupedSlots = useMemo(() => (group ? slots?.data.filter((s) => s.group?.id == group.id) : []), [slots, group])
  const classes = useStyles()
  const onNext = () => {
    const nextStep = step + 1
    setStep(nextStep)
    if (nextStep === 2 && !!slot?.fees?.length) {
      setStep(step + 1)
    }
    if (nextStep === 3) {
      handleSubmit(onSave)()
    }
  }
  const onBack = () => {
    const nextStep = step - 1
    if (nextStep < 0) {
      onClose()
    }
    setStep(nextStep)
  }
  const isDisabled = useMemo(() => {
    switch (step) {
      case 0:
        return !group
      case 1:
        return !slot
      case 2:
        return !fee
    }
  }, [step, group, slot, fee])

  useEffect(() => {
    if (!isDisabled) {
      onNext()
    }
  }, [group, slot, fee])

  useEffect(() => {
    setValue('fee', null)
  }, [slot])

  useEffect(() => {
    setStep(0)
  }, [open])

  return (
    <Drawer open={open}>
      {open && (
        <form onSubmit={handleSubmit(onSave)}>
          <GridContainer className={[classes.gridContainer, step === 0 && classes.selected]}>
            <GridItem xs={12} sm={12} md={12}>
              <Controller
                control={control}
                name="group"
                render={({ field, fieldState: { error } }) => (
                  <AvatarButtonGroup
                    {...field}
                    label={`Choose a ${GROUP_LABEL}`}
                    options={groups?.data}
                    optionSrc="photo"
                    optionLabel="name"
                    size="xxlarge"
                    error={error}
                  />
                )}
              />
            </GridItem>
          </GridContainer>
          {group && (
            <GridContainer className={[classes.gridContainer, step === 1 && classes.selected]}>
              <GridItem xs={12} sm={12} md={12}>
                <Controller
                  control={control}
                  name="slot"
                  render={({ field, fieldState: { error } }) => (
                    <TextButtonGroup
                      {...field}
                      label={`Choose a ${SLOT_LABEL}`}
                      options={groupedSlots}
                      optionLabel="name"
                      optionValue="id"
                      size="xxlarge"
                      error={error}
                    />
                  )}
                />
              </GridItem>
            </GridContainer>
          )}
          {slot && slot?.fees.length > 0 && (
            <GridContainer className={[classes.gridContainer, step === 2 && classes.selected]}>
              <GridItem xs={12} sm={12} md={12}>
                <Controller
                  control={control}
                  name="fee"
                  render={({ field, fieldState: { error } }) => (
                    <TextButtonGroup
                      {...field}
                      label={`Choose a ${FEE_LABEL}`}
                      options={slot?.fees}
                      optionLabel="name"
                      optionValue="id"
                      size="xxlarge"
                      error={error}
                    />
                  )}
                />
              </GridItem>
            </GridContainer>
          )}
          <GridContainer className={[classes.gridContainer, classes.selected]}>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                style={{ marginRight: 10 }}
                disabled={isDisabled}
                onClick={onNext}
              >
                Next
              </Button>
              <Button onClick={onBack}>{step === 0 ? 'Close' : 'Back'}</Button>
            </GridItem>
          </GridContainer>
        </form>
      )}
    </Drawer>
  )
})
export default SlotDateChooserForm
