import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      maxWidth: '95vw',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85vw',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '70vw',
    },
    flexGrow: 1,
    padding: theme.spacing(0, 3),
    minHeight: '70vh',
    maxWidth: '70vw',
    margin: '0 auto',
  },
}))
