import React, { forwardRef } from 'react'
// material-ui components
import { FormGroup, FormControl, FormLabel, FormHelperText } from '@material-ui/core'
import CustomAvatarButton from './AvatarButton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  label: {
    marginBottom: theme.spacing(2),
  },
  group: {
    justifyContent: 'center',
    gap: theme.spacing(2),
  },  
}))

const AvatarButtonGroup = forwardRef(
  (
    { label, options, optionSrc = 'src', optionValue = 'id', optionLabel = 'label', onChange, value, error, ...props },
    ref
  ) => {
    const classes = useStyles()

    return (
      <FormControl ref={ref}>
        <FormLabel className={classes.label}>{label}</FormLabel>
        <FormGroup className={classes.group} row>
          {options.map(({ [optionValue]: id, [optionSrc]: img, [optionLabel]: text }) => (
            <FormControl key={id}>
              <CustomAvatarButton
                {...props}
                text={text}
                img={img}
                selected={id === value?.[optionValue]}
                onClick={() => onChange(options.find((o) => o[optionValue] === id))}
              />
            </FormControl>
          ))}
        </FormGroup>
        {error && <FormHelperText error>{error.message}</FormHelperText>}
      </FormControl>
    )
  }
)
export default AvatarButtonGroup
