import React from 'react'
import { Drawer, Slide } from '@material-ui/core'
import useStyles from './styles'

export default ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <Drawer
      anchor="right"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      {...props}
    >
      {children}
    </Drawer>
  )
}
