import { SLOT_LABEL, GROUP_LABEL, FEE_LABEL } from 'config/app'
import yup from 'utils/yup'

export default yup.object({
  group: yup.object().label(GROUP_LABEL).nullable().required(),
  slot: yup.object().label(SLOT_LABEL).nullable().required(),
  fee: yup
    .object()
    .label(FEE_LABEL)
    .nullable()
    .when('slot', (slot, schema) => (slot?.fees.length > 0 ? schema.required() : schema)),
})
