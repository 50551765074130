import React, { memo } from 'react'
import { LoadingProvider } from 'hooks/useLoading'
import { SnackbarProvider } from 'components/Snackbar'
import Header from 'components/Header/Public'
import Footer from 'components/Footer'
import useStyles from './styles'
import SEO from 'components/seo'
import { NoSsr } from '@material-ui/core'
import { NO_BANNER } from 'config/app'
import { AuthProvider } from 'hooks/usePublicAuth'

export default memo(({ children, title = 'Home', location: { search }, ...props }) => {
  const classes = useStyles()
  const query = new URLSearchParams(search)

  return (
    <NoSsr>
      <SnackbarProvider>
        <AuthProvider search={search}>
          <SEO title={title} />
          <LoadingProvider>
            {!NO_BANNER && !query.get('no-banner') && query.get('no-banner') !== '' && <Header />}
            <div className={classes.root}>{children}</div>
            <Footer />
          </LoadingProvider>
        </AuthProvider>
      </SnackbarProvider>
    </NoSsr>
  )
})
