import React, { forwardRef, useState, useRef, useEffect, useCallback } from 'react'
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'
import useOpenClose from 'hooks/useOpenClose'
import { TNC } from 'config/app'

export default forwardRef(function Terms({ onAccept }, ref) {
  const { open } = useOpenClose(ref)
  const onClose = (e, b) => !b && ref.current.close()
  const [disabled, setDisabled] = useState(true)
  const textRef = useCallback((node) => {
    if (node?.scrollHeight - node?.scrollTop === node?.clientHeight) {
      setDisabled(false)
    }
  }, [])

  const onScroll = (e) => textRef(e.target)

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent ref={textRef}>
        <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{ __html: TNC }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Decline <br/> သဘောမတူပါ
        </Button>
        <Button variant="contained" onClick={onAccept} color="primary" autoFocus>
          Accept <br/> သဘောတူပါသည်
        </Button>
      </DialogActions>
    </Dialog>
  )
})
