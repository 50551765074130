import React from 'react'
// material-ui components
import { Button, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    border: `1px solid ${theme.palette.grey[400]}`,
    textAlign: 'center',
    fontSize: 14,
  },
  selected: {
    backgroundColor: theme.palette.grey[300],
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    fontSize: 15,
    padding: theme.spacing(1, 2),
  },
  xlarge: {
    fontSize: 17,
    padding: theme.spacing(3, 6),
  },
  xxlarge: {
    fontSize: 20,
    padding: theme.spacing(5, 10),
  },
}))

const CustomAvatarButton = ({ label, size = 'large', selected, ...props }) => {
  const classes = useStyles()
  return (
    <Box className={[classes.root, selected && classes.selected, classes[size]]} {...props}>
      {label}
    </Box>
  )
}
export default CustomAvatarButton
