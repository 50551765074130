import React from 'react'
// material-ui components
import { FormHelperText, Avatar, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    border: `2px solid transparent`,
    margin: '0 auto',
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  xlarge: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  xxlarge: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}))

const CustomAvatarButton = ({ img, text, size = 'xlarge', selected, ...props }) => {
  const classes = useStyles()
  return (
    <Container>
      <Avatar {...props} alt={text} src={img} className={[classes.root, selected && classes.selected, classes[size]]} />
      <FormHelperText style={{ textAlign: 'center', fontSize: 18, fontWeight: 500 }}>{text}</FormHelperText>
    </Container>
  )
}
export default CustomAvatarButton
