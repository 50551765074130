import React from 'react'
import { graphql } from 'gatsby'
import SlotSelection from 'components/Public/Slot/selection'

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default function IndexPage(props) {
  return <SlotSelection {...props} />
}
