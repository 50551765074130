import React, { forwardRef, memo } from 'react'
import { addDays, startOfDay, startOfTomorrow } from 'date-fns'
import { GridContainer, GridItem } from 'components/Grid'
import Row from '../Row'
import { CardBody } from 'components/Card'
import { CalendarTodayTwoTone as CalendarTodayTwoToneIcon } from '@material-ui/icons'
import setTimeForDay from 'utils/setTimeForDay'
import SlotSelector from 'components/SlotSelector'

const Timetables = memo(
  forwardRef(({ onSelectTimetable, settings, bookingData }, ref) => {
    const now = new Date()
    const defaultDate = settings.sameDayBooking
      ? now
      : setTimeForDay(settings.bookingCloseTime, now) > now
      ? startOfTomorrow()
      : startOfDay(addDays(now, 2))

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Row avatarIcon={<CalendarTodayTwoToneIcon />} title="Choose time">
            <CardBody>
              <SlotSelector
                defaultDate={defaultDate}
                settings={settings}
                slotId={bookingData?.slot?.id}
                onChange={onSelectTimetable}
              />
            </CardBody>
          </Row>
        </GridItem>
      </GridContainer>
    )
  })
)

export default Timetables
